@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&family=Open+Sans:wght@300;400;500;600;700&family=Signika:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --clr-white: #FFFFFF;
  --clr-black: #202020;
  --clr-grey: #c4c4c4;
  --clr-grey-2: #8f8f8f;
  --clr-bunker: #ebeea1;
  --clr-orange: #ce4f5b;
  -clr-blue-2: #4c46b6;
}

body {
  margin: 0;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202020;
  border-style: solid;
}
/* ------------------------------------------------------ */
/* -- Footer -- */

.footer__copyright{  
  color: #111010;
  background-color:antiquewhite; 
  text-align: center;
  height: 100%;
  width: 100%;
  font-size: 1.1rem;  
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* ------------------------------------------------------ */
/* -- NAVIGATION -- */

nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  background-color: var(--clr-bunker);
  background-color:antiquewhite;
  color: #680e04;
}

.nav__connect-message {
  grid-column: 4;
  padding: 0 25px;
  font-family: "Open Sans";
  font-size: 1.10em;
  font-weight: 500;
  text-align: center;
  color: var(--clr-black);
}


.nav__brand {
  display: flex;
  align-items: center;

  padding: 30px 75px;

  grid-column: 1 / span 3;
}

.nav__brand img {
  max-width: 125px;
  margin-right: 10px;
  height: auto;
  color : #680e04;
}

.nav__brand h1 {
  color: var(--clr-black);

  font-family: "Signika";
  font-size: 2.50em;
  font-weight: 900;
  letter-spacing: 1px;
  color :#680e04;
}

.nav__links {
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}

.nav__links li {
  margin: 0 10px;
}

.nav__links li a {
  text-decoration: none;
  color: var(--clr-grey-2);
  font-weight: 600;
}

.nav__links li a:hover {
  color: var(--clr-black);
}

.nav__connect {
  width: 175px;
  height: 50px;
  margin: 0 75px 0 auto;

  background-color: #680e04;
  
  color: var(--clr-white);

  border: none;

  font-family: "Open Sans";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.nav__connect:hover {
  background-color: var(--clr-grey-2);
  -clr-blue-2: #4c46b6;
}

/* ------------------------------------------------------ */
/* -- HEADER -- */

header {
  background-image: url("./assets/browser.svg");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  
  min-height: 50vh;

  position: relative;
}

.header__subtitle,
.header__title {
  color: var(--clr-black);
  max-width: 450px;
}

.header__subtitle {
  margin: 0 0 25px 75px;
  padding-top: 100px;

  font-family: "Open Sans";
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}

.header__title {
  margin: 0 0 25px 75px;

  font-family: "Merriweather";
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 800;
}

.header__search {
  display: flex;
}

.header__input {
  margin-left: 75px;
  height: 60px;
  padding: 0 20px;
  min-width: 30%;
  border: none;
}

.header__button {
  width: 125px;
  height: 60px;

  background-color: var(--clr-black);
  color: var(--clr-white);

  border: none;

  font-family: "Open Sans";
  font-size: 1em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.header__button:hover {
  background-color: var(--clr-blue-2);
  -clr-blue-2: #4c46b6;
}

.header__search::placeholder {
  font-family: "Open Sans";
  font-size: 1.25em;
  font-weight: 600;
}

/* ------------------------------------------------------ */
/* -- CARDS -- */

.cards__section {
  max-width: 1200px;
  margin: 0 auto 50px;
  padding: 0 20px;
  
}

.cards__section h2 {
  margin-top: 50px;

  font-family: "Merriweather";
  font-size: clamp(2rem, 5vw, 3rem);
  font-weight: 800;

  text-align: center;
}

.cards__section p {
  margin: 10px auto 25px;
  text-align: center;
  font-weight: bold
}

.card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  width: 90%;
  max-width: 800px;
  height: 60px;

  border: 1px solid var(--clr-black);
  margin: 25px auto;
  padding-left: 25px;

  position: relative;

  transition: all 250ms ease;
  background-color:antiquewhite;
  
}

.card__info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  text-align: left;
}

.card__info h3 {
  font-family: "Merriweather";
  font-size: clamp(1rem, 3vw, 1.25rem);
  font-weight: 900;
}

.card__info p {
  font-family: "Merriweather";
  font-size: 1.15rem;
  font-weight: 300;
  margin: 0;
}

.card__info p small {
  font-size: clamp(0.80rem, 2vw, 0.90rem);
}

.card__info p small span {
  font-weight: 700;
}

.card__info p strong {
  font-family: "Merriweather";
  font-size: clamp(1.25rem, 3vw, 1.80rem);
  font-weight: 700;
  margin: 0;
}

.card__button {
  width: 125px;
  height: 100%;

  color : white;
  background-color: #680e04;

  margin-left: auto;
  border: none;

  font-family: "Open Sans";
  font-size: 1em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.card__button:hover {
  background-color: var(--clr-grey-2);
}

.card__button:hover .card__button {
  border-color: var(--clr-grey-2);
}

/* ------------------------------------------------------ */
/* -- MEDIA QUERIES -- */

@media screen and (max-width: 1200px) {
  nav {
    grid-template-columns: repeat(2, 1fr);
  }

  .nav__brand {
    grid-column: 1;
  }

  .nav__links {
    display: none;
  }

  header {
    background-image: none;
  }
}

@media screen and (max-width: 768px) {
  .nav__brand {
    padding: 30px 50px;
  }

  .nav__connect {
    margin: 0 50px 0 auto;
  }
}

/* ------------------------------------------------------ */
/* -- Footer -- */
/* 
.footer__copyright{  
  color: #111010;
  background-color: #e3e3e3;  
  text-align:justify;
  font-size: 1.2rem;  
  font-weight: bold;
} */
